<template>
    <div class="output-layout-component">
        <label>Output Layout</label>
        <div class="mb-3">
            <div class="form-check">
                <input
                    type="radio"
                    id="noAdditionalAttributes"
                    value="none"
                    v-model="outputLayoutType"
                    class="form-check-input"
                    @change="handleOutputLayoutTypeChange"
                />
                <label for="noAdditionalAttributes" class="form-check-label">Don't provide additional attributes in output</label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    id="useExistingLayout"
                    value="existing"
                    v-model="outputLayoutType"
                    class="form-check-input"
                    @change="handleOutputLayoutTypeChange"
                />
                <label for="useExistingLayout" class="form-check-label">Use existing output layout</label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    id="createNewLayout"
                    value="new"
                    v-model="outputLayoutType"
                    class="form-check-input"
                    @change="handleOutputLayoutTypeChange"
                />
                <label for="createNewLayout" class="form-check-label">Create new output layout</label>
            </div>
        </div>

        <div v-if="outputLayoutType === 'existing'">
            <div class="row">
                <div class="col-sm-9 col-xl-6">
                    <v-select
                        v-model="selectedOutputLayout"
                        :options="outputLayouts"
                        label="name"
                        :reduce="layout => layout.slug"
                        @input="loadOutputLayout"
                        placeholder="Select an Output Layout"
                        :clearable="false"
                        class="mb-3"
                    ></v-select>
                </div>
            </div>
        </div>

        <div
            v-if="outputLayoutType === 'new' || (outputLayoutType === 'existing' && selectedOutputLayout)"
            class="mb-2"
        >
            <label
                :for="outputLayoutType === 'new' ? 'newOutputLayoutName' : 'existingOutputLayoutName'"
                class="form-label"
                required
            >
                Output Layout Name
            </label>
            <div class="row">
                <div class="col-sm-9 col-xl-6">
                    <input
                        :id="outputLayoutType === 'new' ? 'newOutputLayoutName' : 'existingOutputLayoutName'"
                        v-model="outputLayoutName"
                        class="form-control mb-2"
                        @input="checkForChanges"
                        placeholder="Output Layout Name"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="outputLayoutType === 'new' || (outputLayoutType === 'existing' && selectedOutputLayout)"
            class="mb-3"
        >
            <label required>Attributes</label>
            <div class="mb-3">
                <small class="form-text text-muted">Attributes used in your selected Criteria Set are automatically included in the output. Additional attributes can be added below.</small>
            </div>
            <div
                v-for="(attribute, index) in displayedAttributes"
                :key="attribute.id || index"
                class="mb-2"
            >
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex align-items-center gap-2">
                            <div class="flex-grow-1">
                                <v-select
                                    v-model="attribute.selected"
                                    :options="getAvailableAttributes(index)"
                                    :get-option-label="(option) => `${option.name} (${option.attribute})`"
                                    :reduce="attr => attr.attribute"
                                    placeholder="Select an attribute or type to search"
                                    :clearable="false"
                                    @input="onAttributeChange(attribute)"
                                >
                                    <template #selected-option="option">
                                        <span :style="{
                                            fontSize: `${Math.max(10, Math.min(14, 14 - ((option.name + option.attribute).length - 32) * 0.45))}px`
                                        }">
                                            {{ option.name }} ({{ option.attribute }})
                                        </span>
                                    </template>
                                </v-select>
                            </div>
                            <div
                                v-if="attribute.selected && getAttributeDescription(attribute.selected)"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                :title="formatDescription(getAttributeName(attribute.selected), getAttributeDescription(attribute.selected))"
                                data-bs-html="true"
                                class="d-flex align-items-center"
                            >
                                <i class="fas fa-info-circle text-primary"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button
                            @click="removeAttribute(index)"
                            class="btn btn-danger"
                        >
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <button @click="addAttribute" class="btn btn-secondary mt-2" :disabled="displayedAttributes.length >= 25">
                    Add Additional Attribute
                </button>
            </div>
            <div v-if="displayedAttributes.length >= 25" class="alert alert-warning mt-3">
                You have reached the maximum limit of 25 attributes. Contact your administrator if you need more.
            </div>
        </div>

        <div
            v-if="outputLayoutType !== 'none'"
            class="mt-3 mb-3"
        >
            <button
                @click="saveOutputLayout"
                class="btn btn-primary"
                :disabled="!isChanged || !isValid"
            >
                Save Output Layout
            </button>
        </div>

        <div v-if="updateSuccess" class="alert alert-success mt-3">
            Output Layout saved successfully.
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted, computed, nextTick } from 'vue';
import axios from 'axios';
import vSelect from 'vue-select';

export default {
    name: 'OutputLayoutComponent',
    components: {
        vSelect,
    },
    props: {
        initialOutputLayoutSlug: {
            type: String,
            default: '',
        },
        selectedCompany: {
            type: String,
            required: true
        }
    },
    emits: [
        'update:outputLayoutSlug',
        'output-layout-changed',
        'output-layout-saved',
        'output-layout-type-changed',
    ],
    setup(props, { emit }) {
        const outputLayoutType = ref(props.initialOutputLayoutSlug ? 'existing' : 'none');
        const selectedOutputLayout = ref(props.initialOutputLayoutSlug);
        const outputLayoutName = ref('');
        const existingAttributes = ref([]);
        const newAttributes = ref([]);
        const outputLayouts = ref([]);
        const availableAttributes = ref([]);

        const originalOutputLayout = ref(null);
        const isChanged = ref(false);
        const updateSuccess = ref(false);

        const displayedAttributes = computed(() => {
            return outputLayoutType.value === 'new' ? newAttributes.value : existingAttributes.value;
        });

        const isValid = computed(() => {
            if (outputLayoutType.value === 'none') return true;
            return (
                outputLayoutName.value.trim() !== '' &&
                displayedAttributes.value.length > 0 &&
                displayedAttributes.value.every(attr => attr.selected)
            );
        });

        const checkForChanges = () => {
            if (!originalOutputLayout.value) return;

            const currentState = {
                name: outputLayoutName.value,
                attributes: outputLayoutType.value === 'new' ? newAttributes.value : existingAttributes.value,
            };

            isChanged.value =
                JSON.stringify(currentState) !== JSON.stringify(originalOutputLayout.value);
            emit('output-layout-changed', isChanged.value);
        };

        watch([outputLayoutName, newAttributes, existingAttributes], checkForChanges, { deep: true });

        const loadOutputLayouts = async () => {
            try {
                const response = await axios.get('/ps/get-outputs-for-user');
                outputLayouts.value = response.data;

            } catch (error) {
                console.error('Error loading output layouts:', error);
            }
        };

        const loadAttributes = async () => {
            try {
                const response = await axios.get('/ps/get-attributes-for-user');
                availableAttributes.value = response.data;
            } catch (error) {
                console.error('Error loading attributes:', error);
            }
        };

        const loadOutputLayout = async () => {
            if (selectedOutputLayout.value && outputLayoutType.value === 'existing') {

                const layout = outputLayouts.value.find(
                    (set) => set.slug === selectedOutputLayout.value
                );

                outputLayoutName.value = layout.name;
                existingAttributes.value = layout.configs.map(attr => ({
                    id: attr.slug,
                    selected: attr.attribute,
                }));
                emit('update:outputLayoutSlug', selectedOutputLayout.value);

                originalOutputLayout.value = {
                    name: outputLayoutName.value,
                    attributes: JSON.parse(JSON.stringify(existingAttributes.value)),
                };
                isChanged.value = false;
                emit('output-layout-changed', false);

            } else {
                resetNewOutputLayout();
            }
        };

        const resetNewOutputLayout = () => {
            outputLayoutName.value = '';
            newAttributes.value = [
                { id: Date.now(), selected: null },
            ];
            originalOutputLayout.value = {
                name: '',
                attributes: [
                    { id: Date.now(), selected: null },
                ],
            };
            isChanged.value = false;
            emit('output-layout-changed', false);
        };

        const addAttribute = () => {
            if (displayedAttributes.value.length < 25) {
                const newAttribute = {
                    id: Date.now(),
                    selected: null,
                };

                if (outputLayoutType.value === 'new') {
                    newAttributes.value.push(newAttribute);
                } else {
                    existingAttributes.value.push(newAttribute);
                }
                checkForChanges();
            }
        };

        const removeAttribute = (index) => {
            displayedAttributes.value.splice(index, 1);
            if (displayedAttributes.value.length === 0) {
                addAttribute();
            }
            checkForChanges();
        };

        const saveOutputLayout = async () => {
            try {
                if (outputLayoutType.value === 'none') {
                    // Handle saving the 'none' option
                    emit('output-layout-saved', null);
                    updateSuccess.value = true;
                    setTimeout(() => {
                        updateSuccess.value = false;
                    }, 3000);
                    return;
                }

                const payload = {
                    output_layout: {
                        name: outputLayoutName.value,
                        attributes: displayedAttributes.value.map(attr => ({
                            attribute: attr.selected,
                            slug: attr.id,
                        })),
                    },
                    company: props.selectedCompany
                };

                let response;
                let savedOutputLayoutSlug;

                if (outputLayoutType.value === 'new') {
                    response = await axios.post('/ps/output-layout', payload);
                    savedOutputLayoutSlug = response.data.slug;
                } else {
                    response = await axios.put(`/ps/output-layout/${selectedOutputLayout.value}`, payload);
                    savedOutputLayoutSlug = response.data.slug;
                }

                await reloadOutputLayouts(savedOutputLayoutSlug);

                updateSuccess.value = true;
                emit('output-layout-saved', savedOutputLayoutSlug);

                setTimeout(() => {
                    updateSuccess.value = false;
                }, 3000);
            } catch (error) {
                console.error('Error saving output layout:', error);
                alert('Error saving output layout. Please try again.');
            }
        };

        const reloadOutputLayouts = async (outputLayoutSlugToSelect) => {
            try {
                await loadOutputLayouts();
                outputLayoutType.value = 'existing';

                let layoutToSelect = outputLayouts.value.find(
                    (layout) => layout.slug === outputLayoutSlugToSelect
                );

                if (layoutToSelect) {
                    selectedOutputLayout.value = layoutToSelect.slug;
                    outputLayoutName.value = layoutToSelect.name;

                    await loadOutputLayout();
                } else {
                    console.error('Could not find the saved output layout in the reloaded list');
                    if (outputLayouts.value.length > 0) {
                        selectedOutputLayout.value = outputLayouts.value[0].slug;
                        outputLayoutName.value = outputLayouts.value[0].name;
                        await loadOutputLayout();
                    }
                }

                isChanged.value = false;
                emit('update:outputLayoutSlug', selectedOutputLayout.value);
            } catch (error) {
                console.error('Error reloading output layouts:', error);
            }
        };

        const handleOutputLayoutTypeChange = () => {
            emit('output-layout-type-changed', outputLayoutType.value);
            if (outputLayoutType.value === 'new') {
                resetNewOutputLayout();
            } else if (outputLayoutType.value === 'existing') {
                loadOutputLayout();
            } else {
                // Handle 'none' option
                selectedOutputLayout.value = null;
                outputLayoutName.value = '';
                existingAttributes.value = [];
                newAttributes.value = [];
                originalOutputLayout.value = null;
                isChanged.value = false;
                emit('output-layout-changed', false);
                emit('update:outputLayoutSlug', null);
            }
        };

        const getAvailableAttributes = (currentIndex) => {
            return availableAttributes.value.filter(attr =>
                !displayedAttributes.value.some((selected, index) =>
                    index !== currentIndex && selected.selected === attr.attribute
                )
            );
        };

        watch(
            () => props.initialOutputLayoutSlug,
            (newValue) => {
                if (newValue) {
                    outputLayoutType.value = 'existing';
                    selectedOutputLayout.value = newValue;
                    loadOutputLayout();
                }
            }
        );

        watch(selectedOutputLayout, () => {
            loadOutputLayout();
        });

        watch(outputLayoutType, (newValue) => {
            if (newValue === 'new') {
                resetNewOutputLayout();
            } else {
                selectedOutputLayout.value = null;
                outputLayoutName.value = '';
                existingAttributes.value = [];
                originalOutputLayout.value = null;
                isChanged.value = false;
                emit('output-layout-changed', false);
            }
        });

        const formatDescription = (name, description) => {
            if (!description) return `<strong>${name}</strong>`;
            return `<strong>${name}</strong><br>${description}`
                .replace(/\\n/g, '<br>')
                .replace(/• /g, '&bull; ')
                .replace(/\s+/g, ' ')
                .trim();
        };

        const getAttributeDescription = (attributeKey) => {
            const attribute = availableAttributes.value.find(attr => attr.attribute === attributeKey);
            return attribute ? attribute.description : null;
        };

        const getAttributeName = (attributeKey) => {
            const attribute = availableAttributes.value.find(attr => attr.attribute === attributeKey);
            return attribute ? attribute.name : attributeKey;
        };

        const initializeTooltips = () => {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipTriggerList.forEach(tooltipTriggerEl => {
                const tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) {
                    tooltip.dispose();
                }
                new bootstrap.Tooltip(tooltipTriggerEl, {
                    html: true
                });
            });
        };

        const onAttributeChange = async (attribute) => {
            checkForChanges();
            await nextTick();
            initializeTooltips();
        };

        watch(displayedAttributes, () => {
            nextTick(() => {
                initializeTooltips();
            });
        }, { deep: true });

        onMounted(async () => {
            await loadOutputLayouts();
            await loadAttributes();
            if (props.initialOutputLayoutSlug) {
                loadOutputLayout();
            } else {
                selectedOutputLayout.value = null;
                outputLayoutName.value = '';
                existingAttributes.value = [];
            }
            initializeTooltips();
        });

        return {
            outputLayoutType,
            selectedOutputLayout,
            outputLayoutName,
            displayedAttributes,
            outputLayouts,
            availableAttributes,
            isChanged,
            isValid,
            updateSuccess,
            loadOutputLayout,
            addAttribute,
            removeAttribute,
            saveOutputLayout,
            checkForChanges,
            handleOutputLayoutTypeChange,
            reloadOutputLayouts,
            getAvailableAttributes,
            formatDescription,
            getAttributeDescription,
            getAttributeName,
            onAttributeChange,
        };
    },
};
</script>

<style>
.vs__selected {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.vs__selected span {
    line-height: 1.2;
    white-space: normal;
}

.tooltip-inner {
    text-align: left !important;
    max-width: 500px !important;
    white-space: normal !important;
}
</style>

<template>
    <div>
        <form @submit.prevent="updateCriteriaConfigs">
            <div class="card-body">
                <!-- Success Banner -->
                <div v-if="updateSuccess" class="alert alert-success mt-3">
                    Criteria Set updated successfully.
                </div>

                <!-- Criteria Set Form -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="name" required>Criteria Set Name</label>
                            <input type="text" id="name" class="form-control" v-model="criteriaSet.name" required>
                        </div>
                    </div>
                </div>

                <!-- Criteria Configs -->
                <label for="criteria" required>Criteria</label>

                <div v-for="(config, index) in criteriaConfigs" :key="index">
                    <div :class="{'border': config.to_be_deleted, 'border-danger': config.to_be_deleted, 'my-2': config.to_be_deleted, 'p-2': config.to_be_deleted}">
                        <div v-if="config.to_be_deleted">
                            <strong>Marked for deletion</strong>
                        </div>
                        <div class="row mb-3">
                            <!-- Attribute Selection -->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <v-select
                                        v-model="config.attribute"
                                        :options="attributes"
                                        label="name"
                                        :reduce="attribute => attribute.attribute"
                                        placeholder="Select an option or type to search"
                                        :clearable="false"
                                        required
                                        @input="onAttributeChange(config)"
                                    ></v-select>
                                </div>
                            </div>

                            <!-- Rule Selection -->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select class="form-select" v-model="config.rule" required @change="onRuleChange(config)">
                                        <option value="" disabled>Select a rule</option>
                                        <option
                                            v-for="rule in getAvailableRules(getAttributeObject(config.attribute))"
                                            :key="rule.value"
                                            :value="rule.value"
                                        >
                                            {{ rule.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Value Input (conditionally rendered) -->
                            <div class="col-md-3" v-if="shouldShowValueInput(config.rule)">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="config.value" required>
                                </div>
                            </div>

                            <!-- Remove Criteria Button -->
                            <div :class="{'col-md-1': shouldShowValueInput(config.rule), 'col-md-4': !shouldShowValueInput(config.rule)}">
                                <div>
                                    <button
                                        v-if="!config.to_be_deleted"
                                        type="button"
                                        class="btn btn-danger btn-block"
                                        @click="removeCriteriaConfig(index)"
                                    >
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-danger btn-block"
                                        @click="cancelDeleteCriteriaConfig(index)"
                                    >
                                        <i class="fas fa-undo-alt" style="font-size: 1.5rem; cursor: pointer;"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Add Criteria Button -->
                <button type="button" class="btn btn-sm btn-secondary" @click="addCriteriaConfig">Add Additional Criteria</button>
            </div>

            <!-- Form Footer -->
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">Save Criteria Set</button>
                    </div>
                    <div class="col text-end">
                        <button type="button" class="btn btn-danger" @click="showDeleteWarning" v-if="criteriaConfigData.slug">
                            <i class="fa-solid fa-trash fa-fw"></i> Delete Criteria Set
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    components: {
        vSelect
    },
    props: {
        criteriaConfigData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            attributes: [],
            updateSuccess: false,
            criteriaSet: {
                name: this.criteriaConfigData.name,
            },
            criteriaConfigs: this.criteriaConfigData.criteria_configs.map(config => ({
                ...config,
                attribute: config.attribute || null,
            })),
            allRules: [
                { value: '==', text: '= Equals' },
                { value: '!=', text: '!= Does Not Equal' },
                { value: '>', text: '> Greater Than' },
                { value: '<', text: '< Less Than' },
                { value: '>=', text: '>= Greater Than or Equal To' },
                { value: '<=', text: '<= Less Than or Equal To' },
                { value: 'IN', text: 'IN' },
                { value: 'NOT IN', text: 'NOT IN' },
                { value: 'isNull', text: 'Is Null' },
                { value: 'isNotNull', text: 'Is Not Null' },
            ],
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    methods: {
        updateCriteriaConfigs() {
            const criteriaSlug = this.criteriaConfigData.slug;

            const transformedCriteriaConfigs = this.criteriaConfigs
                .filter(config => !config.to_be_deleted)
                .map(config => ({
                    ...config,
                    attribute: config.attribute,
                    value: this.shouldShowValueInput(config.rule) ? config.value : null
                }));

            axios.put(`/ps/criteria-set/${criteriaSlug}`, {
                name: this.criteriaSet.name,
                criteria_configs: transformedCriteriaConfigs
            })
                .then(response => {
                    this.updateSuccess = true;
                    window.location.href = '/ps/criteria-sets';
                })
                .catch(error => {
                    console.error('Error updating Criteria Set and Configurations:', error);
                });
        },
        addCriteriaConfig() {
            this.criteriaConfigs.push({
                attribute: null,
                rule: '',
                value: '',
            });
        },
        removeCriteriaConfig(index) {
            const config = this.criteriaConfigs[index];

            if (config.slug) {
                config.to_be_deleted = true;
            } else {
                this.criteriaConfigs.splice(index, 1);
            }
        },
        cancelDeleteCriteriaConfig(index) {
            const config = this.criteriaConfigs[index];

            if (config.slug && config.to_be_deleted) {
                config.to_be_deleted = false;
            }
        },
        fetchAttributes() {
            axios.get('/ps/get-attributes-for-user')
                .then(response => {
                    this.attributes = response.data;
                    this.initializeCriteriaConfigs();
                })
                .catch(error => {
                    console.error('Error fetching attributes:', error);
                });
        },
        initializeCriteriaConfigs() {
            this.criteriaConfigs = this.criteriaConfigData.criteria_configs.map(config => ({
                ...config,
                attribute: this.getAttributeByValue(config.attribute)?.attribute || null,
            }));
        },
        getAttributeByValue(value) {
            return this.attributes.find(attr => attr.attribute === value);
        },
        getAttributeObject(value) {
            return this.attributes.find(attr => attr.attribute === value) || {};
        },
        showDeleteWarning() {
            const confirmation = window.confirm('Are you sure you want to delete this criteria set?');

            if (confirmation) {
                this.deleteCriteria();
            }
        },
        deleteCriteria() {
            const criteriaSlug = this.criteriaConfigData.slug;

            if (criteriaSlug) {
                axios.delete(`/ps/criteria-set/delete/${criteriaSlug}`)
                    .then(response => {
                        this.updateSuccess = true;
                    })
                    .catch(error => {
                        console.error('Error deleting Criteria Set and Configurations:', error);
                    });
                setTimeout(() => {
                    window.location.href = '/ps/criteria-sets';
                }, 2000);
            }
        },
        getAvailableRules(attribute) {
            if (!attribute || !attribute.type) {
                // Return default rules excluding numeric operators
                return this.allRules.filter(
                    op => !['>', '<', '>=', '<='].includes(op.value)
                );
            }

            if (attribute.type === 'int' || attribute.type === 'decimal') {
                // Include all rules for numeric types
                return this.allRules;
            }

            // Exclude numeric operators for other data types
            return this.allRules.filter(
                op => !['>', '<', '>=', '<='].includes(op.value)
            );
        },
        onAttributeChange(config) {
            const attribute = this.getAttributeObject(config.attribute);
            const availableRules = this.getAvailableRules(attribute);
            if (!availableRules.find(rule => rule.value === config.rule)) {
                config.rule = '';
            }
        },
        onRuleChange(config) {
            if (!this.shouldShowValueInput(config.rule)) {
                config.value = '';
            }
        },
        shouldShowValueInput(rule) {
            return rule !== 'isNull' && rule !== 'isNotNull';
        },
    },
    watch: {
        criteriaConfigs: {
            handler(newConfigs, oldConfigs) {
                newConfigs.forEach((config, index) => {
                    const oldConfig = oldConfigs[index] || {};
                    if (config.attribute !== oldConfig.attribute) {
                        const attribute = this.getAttributeObject(config.attribute);
                        const availableRules = this.getAvailableRules(attribute);
                        if (!availableRules.find(rule => rule.value === config.rule)) {
                            config.rule = '';
                        }
                    }
                    if (!this.shouldShowValueInput(config.rule)) {
                        config.value = '';
                    }
                });
            },
            deep: true,
        },
    },
};
</script>
